import React, {useEffect, useState} from 'react';
import "./Step4Telegram.css";
import {QRCodeSVG } from 'qrcode.react';
import {BASE_URL} from "../../config";
import axios from 'axios';
import {getCurrentFormattedDateTime} from "../../tools/dateTools";
import LoadingArrow from './loadingArrow.svg'

const Step4Telegram = ({ formData }) => {
    const [requestDone, setRequestDone] = useState(false);
    const [response, setResponse] = useState(null);
    const [chargesAgreed, setChargesAgreed] = useState(false);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [termsDate, setTermsDate] = useState(null);
    const [agreedDate, setAgreedDate] = useState(null);

    const handleChargesChange = (event) => {
        setChargesAgreed(event.target.checked);
        setAgreedDate(getCurrentFormattedDateTime());
    };

    const handleTermsChange = (event) => {
        setTermsAgreed(event.target.checked);
        setTermsDate(getCurrentFormattedDateTime());
    };

    useEffect(() => {
        const sendRequest = async () => {
            setLoading(true);
            try {
                const payload = {
                    ageAgree: agreedDate,
                    termsAgree: termsDate,
                    avatarId: formData.avatarId,
                    interests: formData.interests,
                };

                // Add affiliate info if tolt_referral exists
                if (window.tolt_referral) {
                    payload.affiliateId = window.tolt_referral;
                    payload.affiliateType = 'tolt';
                }

                const res = await axios.post(`${BASE_URL}/telegram/register`, payload);
                setResponse(res.data);
                setRequestDone(true);
            } catch (error) {
                alert(error.response?.data?.error || error.message);
            } finally {
                setLoading(false);
            }
        };

        if (chargesAgreed && termsAgreed && !requestDone) {
            sendRequest();
        }
    }, [chargesAgreed, termsAgreed, requestDone, formData]);


    return (<>
        <h1 style={{marginBottom: '120px'}}>Start chat</h1>
        <div className="inner-container">
            <div className="check-wrapper">
                <input type="checkbox" onChange={handleChargesChange} checked={chargesAgreed} id="charges-check"/>
                <label htmlFor="charges-check">I confirm that I am over 18 years old and agree to the <a href="https://www.mymistress.ai/terms/underage-policy"
                                                                 target="_blank">Underage Policy</a></label>
            </div>

            <div className="check-wrapper">
                <input type="checkbox" onChange={handleTermsChange} checked={termsAgreed} id="terms-check"/>
                <label htmlFor="terms-check">I agree to the <a href="https://www.mymistress.ai/terms/terms-of-business" target="_blank">Terms of Service</a> and 
                    <a href="https://www.mymistress.ai/terms/privacy-policy" target="_blank">Privacy Policy</a></label>
            </div>

            <div style={{textAlign: "center", marginTop: '120px'}}>
                <a className={"telegram-button" + (requestDone ? "" : " disabled")}
                   href={requestDone ? response.telegramLink : "javascript:void(0)"}>
                    Open Telegram
                    {loading && <img src={LoadingArrow}/>}

                </a>
                {requestDone ? <>
                    <div>
                        <div className="qr-wrapper">
                            <QRCodeSVG value={response.telegramLink} size={124} level="H"/>
                        </div>
                    </div>
                    <div>Scan QR Code with you Phone</div>
                </> : <>
                    <div>Please agree to the above terms first</div>
                </>}

            </div>

        </div>
    </>)
}

export default Step4Telegram;